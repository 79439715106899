@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.App {
	font-family: 'EB Garamond', serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }

  .toggle-base {
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	/* put the height and width of your image here */
	height: 300px;
    width: 500px;
	border: none;
  }

  #light .toggle {
	@extend .toggle-base;
	background-image: url('./Assets/Images/light1.svg');
  }
  
  #dark .toggle {
	@extend .toggle-base;
	background-image: url('./Assets/Images/dark1.svg');
  }

 #dark  {
	background-color: #402819;
	min-height: 100vh;
  }
  #light {
	background-color: #C7B89D;
	min-height: 100vh;
  }

  #light .main1{
	p{
	color: #402819;
	}
	strong{
	color: #402819;
	font-weight: bold;
	font-size: 50px;
	}


  }

  #dark .main1{
	p{
	color: #C7B89D;
	}
	strong{
		color: #C7B89D;
		font-weight: bold;
		font-size: 50px;
	}
  }

  #light .nav-link{
	color: #402819;
  }
  
  #dark .nav-link{
	color: #C7B89D;
  }

  #light .tab-content>.active {
	color: #402819;
  }

  #dark .tab-content>.active {
	color: #C7B89D;
  }

