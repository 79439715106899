.nav-tabs {
  display: 'block';
  width: 700;
  padding: 30;
  --bs-nav-tabs-border-radius: 50%;
  --bs-nav-tabs-border-color: #609966;
  --bs-nav-tabs-link-active-color: #609966;
  --bs-nav-tabs-link-active-border-color: #609966;
  --bs-nav-tabs-link-active-bg: none;
  --bs-nav-tabs-link-hover-border-color: #609966;
}

.nav {
  display: flex;
  flex-wrap: inherit;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
}

.nav-link{
  height: -webkit-fit-content;
}

.tab-content>.active {
  display: block;
  margin-top: 10px;
  max-width: 100vh;
}

.abt{
  font-size: medium;
  letter-spacing: 0.5px;
}

