.btn-n{
    display: flex;
    justify-content: space-evenly;
}

  .btndown {
	background-image: url('../Images/downl.svg');
    background-color: transparent;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border: none;
    width: 50px;
    height: 50px;
 }